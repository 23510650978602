<template>
  <div class="page">
    <el-radio-group v-model="subject" @change="getList">
      <el-radio-button label="1">物理</el-radio-button>
      <el-radio-button label="4">历史</el-radio-button>
    </el-radio-group>
    <div class="choose">
      <div @click="(order = 'id'), getList()" :class="{ fx: order == 'id' }">
        默认排序
      </div>
      <div @click="(order = 'score'), getList()" :class="{ fx: order == 'score' }">
        分数排序
      </div>

      <el-button size="mini" type="primary" style="margin:2px 50px" @click="dialogVisible = true">
        添加用户
      </el-button>

      <!-- 查询下级用户 -->
      <el-select v-model="parent_id" clearable class="filter-item" placeholder="请选择老师" size="mini" style="width: 150px"
        @change="getTeacher">
        <el-option v-for="(item, i) in ExpertList" :key="i" :value="item.id" :label="item.name" />
      </el-select>

      <!-- 查询按钮 -->
      <el-button size="mini" type="primary" style="margin:2px 50px" @click="getList()">
        查询
      </el-button>

    </div>

    <!-- 用户列表 -->
    <div>
      <user_list :UserListData="UserListData" :ExpertList="ExpertList"> </user_list>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination :page-size="tables.pageSize" :total="tables.total" class="page-box" layout="prev, pager, next"
        @current-change="pageChange" />
    </div>

    <!-- 添加学生弹窗 -->
    <el-dialog :visible.sync="dialogVisible" class="cre" title='添加学员信息' width="1080px" append-to-body destroy-on-close>
      <create_user @closeEdit='close' :userData="null"></create_user>
    </el-dialog>
  </div>
</template>

<script>
import user_list from "./components/UserList";
import create_user from "./components/editUser";
//time: 2021/1/22
export default {
  name: "User",
  components: {
    user_list,
    create_user,
  },
  data() {
    return {
      subject: 1,
      UserListData: [],
      ExpertInfo: "",
      order: "id",
      dialogVisible: false,
      tables: {
        currentPage: 1,
        pageSize: 12,
        total: 1,
      },
      parent_id: null,
      ExpertList: []
    };
  },
  created() {

    // 获取下级导师列表
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    const data = {
      parent_id: this.ExpertInfo.id,
      pageSize: 123,
    };
    this.$fecth.post("expert/getData", data).then((res) => {
      const { code, data } = res;
      if (code == 200) {
        this.ExpertList = data.lists;
        // this.parent_id = Number(localStorage.getItem("teacher_id"))
        this.getList();
      }
    });
  },
  methods: {

    // 获取学生列表
    getList() {

      let id = this.ExpertInfo.id

      //判断是否选择下级导师
      if (this.parent_id) {
        id = this.parent_id
      }

      let data = {
        subject: this.subject,
        parent_id: id,
        order: this.order,
        pageSize: this.tables.pageSize,
        pageNum: this.tables.currentPage,
      };

      this.$fecth.post("user/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.UserListData = data.lists;
          this.tables.total = data.total;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    close() {
      this.dialogVisible = false;
      this.getList();
    },
    pageChange(val) {
      this.tables.currentPage = val;
      this.getList();
    },
    getTeacher() {
      if (this.parent_id) {
        localStorage.setItem('teacher_id', this.parent_id)
      } else {
        localStorage.removeItem('teacher_id')
      }
    }

  },
};
</script>
<style lang="less" scoped>
.pagination {
  background: white;
  padding: 0;
}

.el-dialog__body {
  min-height: 200px;
}

.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 4px 0 0;
}

.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 4px 0 0 0;
}
</style>



<style lang="less" scoped>
.choose {
  background: white;
  margin-bottom: 12px;
  padding: 3px;
  display: flex;
  line-height: 25px;

  .fx {
    border-bottom: 1px solid rgb(11, 108, 218);
  }

  div {
    width: 80px;
    margin: 5px 0 5px 75px;
  }
}
</style>